import React from "react";

// const Toaster = React.lazy(() =>
//   import("./views/notifications/toaster/Toaster")
// );
// const Tables = React.lazy(() => import("./views/base/tables/Tables"));

// const Breadcrumbs = React.lazy(() =>
//   import("./views/base/breadcrumbs/Breadcrumbs")
// );
// const Cards = React.lazy(() => import("./views/base/cards/Cards"));
// const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"));
// const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"));
// const BasicForms = React.lazy(() => import("./views/base/forms/BasicForms"));

// const Jumbotrons = React.lazy(() =>
//   import("./views/base/jumbotrons/Jumbotrons")
// );
// const ListGroups = React.lazy(() =>
//   import("./views/base/list-groups/ListGroups")
// );
// const Navbars = React.lazy(() => import("./views/base/navbars/Navbars"));
// const Navs = React.lazy(() => import("./views/base/navs/Navs"));
// const Paginations = React.lazy(() =>
//   import("./views/base/paginations/Pagnations")
// );
// const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"));
// const ProgressBar = React.lazy(() =>
//   import("./views/base/progress-bar/ProgressBar")
// );
// const Switches = React.lazy(() => import("./views/base/switches/Switches"));

// const Tabs = React.lazy(() => import("./views/base/tabs/Tabs"));
// const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"));
// const BrandButtons = React.lazy(() =>
//   import("./views/buttons/brand-buttons/BrandButtons")
// );
// const ButtonDropdowns = React.lazy(() =>
//   import("./views/buttons/button-dropdowns/ButtonDropdowns")
// );
// const ButtonGroups = React.lazy(() =>
//   import("./views/buttons/button-groups/ButtonGroups")
// );
// const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"));
// const Charts = React.lazy(() => import("./views/charts/Charts"));

// const CoreUIIcons = React.lazy(() =>
//   import("./views/icons/coreui-icons/CoreUIIcons")
// );
// const Flags = React.lazy(() => import("./views/icons/flags/Flags"));
// const Brands = React.lazy(() => import("./views/icons/brands/Brands"));
// const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"));
// const Badges = React.lazy(() => import("./views/notifications/badges/Badges"));
// const Modals = React.lazy(() => import("./views/notifications/modals/Modals"));

// const Widgets = React.lazy(() => import("./views/widgets/Widgets"));

// const Users = React.lazy(() => import("./views/users/Users"));
// const User = React.lazy(() => import("./views/users/User"));

// Go Tennis
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Customers = React.lazy(() =>
  import("./views/Admin/coustomers/Coustomer")
);
const CustomerDetails = React.lazy(() =>
  import("./views/Admin/coustomers/customerDetails")
);
const Courts = React.lazy(() => import("./views/Admin/courts/Courts"));
const MaintainCourt = React.lazy(() =>
  import("./views/Admin/courts/maintaincourt")
);
const CheckInList = React.lazy(() => import("./views/Admin/bookings/Bookings"));
const BookingDetails = React.lazy(() =>
  import("./views/Admin/bookings/bookingDetails")
);
const Profile = React.lazy(() => import("./views/Admin/Profile/profile"));

// QR Location
const QRLocation = React.lazy(() =>
  import("./views/Admin/QR Location/QrLocation")
);
const QRLocationDetail = React.lazy(() =>
  import("./views/Admin/QR Location/QRlocationDetail")
);

// Group
const Groups = React.lazy(() => import("./views/Admin/Group/group"));
const GroupDetail = React.lazy(() =>
  import("./views/Admin/Group/group-detail")
);

// Reports
const Reports = React.lazy(() => import("./views/Admin/reports/reports"));
const ReportDetail = React.lazy(() =>
  import("./views/Admin/reports/reportDetail")
);
const Miscellaneous = React.lazy(() =>
  import("./views/Admin/Miscellaneous/miscellaneous")
);
const Rewards = React.lazy(() =>
  import("./views/Admin/coustomers/rewardsHistory")
);
const routes = [
  //Go Tennis
  { path: "/", exact: true, name: "Home", component: Dashboard },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/profile", name: "Profile", component: Profile, exact: true },
  { path: "/customers", name: "Customers", component: Customers },
  {
    path: "/customer/detail/:customerid",
    name: "Customer Details",
    component: CustomerDetails,
  },
  {
    path: "/miscellaneous",
    name: " Miscellaneous",
    component: Miscellaneous,
  },
  { path: "/courts", name: "Courts", component: Courts },
  {
    path: "/maintaincourt/:courtid",
    name: "Maintain Court",
    component: MaintainCourt,
  },
  { path: "/checkin-list", name: "CheckIn-List", component: CheckInList },
  {
    path: "/booking/detail/:bookingid",
    name: "Booking Details",
    component: BookingDetails,
  },
  {
    path: "/group-detail/:id",
    name: "GroupDetail",
    component: GroupDetail,
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
  },
  {
    path: "/qrlocation",
    name: "QRLocations",
    component: QRLocation,
  },
  {
    path: "/qrlocationdetail/:id",
    name: "QRLocationDetail",
    component: QRLocationDetail,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/report/:id",
    name: "Report",
    component: ReportDetail,
  },
  {
    path: "/rewards/history/:customerid",
    name: "Rewards",
    component: Rewards,
  },
  // { path: "/base", name: "Base", component: Cards, exact: true },
  // { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
  // { path: "/base/cards", name: "Cards", component: Cards },
  // { path: "/base/carousels", name: "Carousel", component: Carousels },
  // { path: "/base/collapses", name: "Collapse", component: Collapses },
  // { path: "/base/forms", name: "Forms", component: BasicForms },
  // { path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
  // { path: "/base/list-groups", name: "List Groups", component: ListGroups },
  // { path: "/base/navbars", name: "Navbars", component: Navbars },
  // { path: "/base/navs", name: "Navs", component: Navs },
  // { path: "/base/paginations", name: "Paginations", component: Paginations },
  // { path: "/base/popovers", name: "Popovers", component: Popovers },
  // { path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar },
  // { path: "/base/switches", name: "Switches", component: Switches },
  // { path: "/base/tables", name: "Tables", component: Tables },
  // { path: "/base/tabs", name: "Tabs", component: Tabs },
  // { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
  // { path: "/buttons", name: "Buttons", component: Buttons, exact: true },
  // { path: "/buttons/buttons", name: "Buttons", component: Buttons },
  // {
  //   path: "/buttons/button-dropdowns",
  //   name: "Dropdowns",
  //   component: ButtonDropdowns,
  // },
  // {
  //   path: "/buttons/button-groups",
  //   name: "Button Groups",
  //   component: ButtonGroups,
  // },
  // {
  //   path: "/buttons/brand-buttons",
  //   name: "Brand Buttons",
  //   component: BrandButtons,
  // },
  // { path: "/charts", name: "Charts", component: Charts },
  // { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
  // { path: "/icons/coreui-icons", name: "CoreUI Icons", component: CoreUIIcons },
  // { path: "/icons/flags", name: "Flags", component: Flags },
  // { path: "/icons/brands", name: "Brands", component: Brands },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   component: Alerts,
  //   exact: true,
  // },
  // { path: "/notifications/alerts", name: "Alerts", component: Alerts },
  // { path: "/notifications/badges", name: "Badges", component: Badges },
  // { path: "/notifications/modals", name: "Modals", component: Modals },
  // { path: "/notifications/toaster", name: "Toaster", component: Toaster },
  // { path: "/widgets", name: "Widgets", component: Widgets },
  // { path: "/users", exact: true, name: "Users", component: Users },
  // { path: "/users/:id", exact: true, name: "User Details", component: User },
];

export default routes;
