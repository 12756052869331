import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import userAuth from "./userAuth";


class PrivateRouter extends Component {

    render() {
        let isLogin = userAuth() ? true : false;
        return (
            <Route render={props => (
                isLogin ?
                    <React.Fragment>
                        <Route exact={this.props.exact} component={this.props.component} {...this.props} />
                    </React.Fragment>
                    : <Redirect to="/login" />
            )} />
        );
    }
}

export default PrivateRouter;