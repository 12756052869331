import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import userAuth from './userAuth';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    var isLogin = userAuth() ? true : false;
    return (
        <Switch>
            <Route {...rest} render={props => (
                isLogin ? <Redirect to="/dashboard" /> : <Component {...props} />
            )} />
        </Switch>
    );
};

export default PublicRoute;