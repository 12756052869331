import React, { Component } from "react";
import { HashRouter, Switch } from "react-router-dom";
import "./scss/style.scss";
import "./css/style.css";
import "react-toastify/dist/ReactToastify.css";
import PublicRouter from "./Routers/PublicRouter";
import PrivateRouter from "./Routers/PrivateRouter";
import routes from "./routes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Privacy_policy = React.lazy(() =>
  import("./views/pages/privacy_policy/policy")
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <PublicRouter
              component={Login}
              path="/login"
              name="Login Page"
              exact
              {...this.props}
            />
            <PublicRouter
              component={Privacy_policy}
              path="/privacy_policy"
              name="Privacy Policy"
            />
            {routes.map((route, i) => {
              return (
                <PrivateRouter
                  key="privateRouter"
                  path={route.path}
                  name={route.name}
                  render={(props) => <TheLayout {...props} />}
                />
              );
            })}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
